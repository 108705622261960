<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'แนะนำเพื่อน']" />
    <user-affiliate></user-affiliate>
  </div>
</template>

<script>
import UserAffiliate from "@/components/UserAffiliate.vue"
export default {
  components: { UserAffiliate },
}
</script>