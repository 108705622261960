var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('loading'):_vm._e(),(!_vm.isLoading)?_c('div',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"column-filter":{ external: true },"items-per-page-select":{ label: 'จำนวนแถว' },"table-filter-value":_vm.tableFilterValue,"column-filter-value":_vm.columnFilterValue,"sorter-value":_vm.sorterValue,"items-per-page":_vm.itemsPerPage,"loading":_vm.tableLoading,"noItemsView":{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      },"hover":"","sorter":"","dark":"","border":"","addTableClasses":"w-100"},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:columnFilterValue":function($event){_vm.columnFilterValue=$event},"update:column-filter-value":[function($event){_vm.columnFilterValue=$event},_vm.columnFilter],"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"pagination-change":_vm.paginationChange},scopedSlots:_vm._u([{key:"userRefId",fn:function({ item }){return [_c('td',[(item.userRefId !== 0)?_c('router-link',{attrs:{"to":'/members/' + item.userRefId,"target":"_blank"}},[_vm._v(" "+_vm._s(item.userRefId)+" ")]):_vm._e(),(item.userRefId === 0)?_c('span',[_vm._v("-")]):_vm._e()],1)]}},{key:"detail",fn:function({ item }){return [_c('td',[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
            name: 'AffiliateStatement',
            params: { userId: item.userId },
          }}},[_vm._v("รายได้")])],1)]}}],null,false,1506398735)}),_c('CPagination',{attrs:{"active-page":_vm.activePage,"double-arrows":false,"pages":_vm.totalPage,"dots":false},on:{"update:activePage":_vm.updateActivePage}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }