<template>
  <div>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <CDataTable :items="items" :fields="fields" :column-filter="{ external: true }"
        :items-per-page-select="{ label: 'จำนวนแถว' }" :table-filter-value.sync="tableFilterValue"
        :column-filter-value.sync="columnFilterValue" :sorter-value.sync="sorterValue" :items-per-page.sync="itemsPerPage"
        :loading="tableLoading" :noItemsView="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }" v-on:pagination-change="paginationChange" v-on:update:column-filter-value="columnFilter" hover sorter dark
        border addTableClasses="w-100">
        <template #userRefId="{ item }">
          <td>
            <router-link v-if="item.userRefId !== 0" :to="'/members/' + item.userRefId" target="_blank">
              {{ item.userRefId }}
            </router-link>
            <span v-if="item.userRefId === 0">-</span>
          </td>
        </template>
        <template #detail="{ item }">
          <td>
            <router-link :to="{
              name: 'AffiliateStatement',
              params: { userId: item.userId },
            }" class="btn btn-primary">รายได้</router-link>
          </td>
        </template>
      </CDataTable>
      <CPagination :active-page="activePage" :double-arrows="false" :pages="totalPage" :dots="false"
        v-on:update:activePage="updateActivePage" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "userId",
          label: "#สมาชิก",
          sorter: false,
        },
        {
          key: "userRefId",
          label: "#สมานิกแนะนำ",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "earningSum",
          label: "รายได้ทั้งหมด",
          sorter: false,
          filter: false,
        },
        {
          key: "betSum",
          label: "แทงไปแล้ว",
          sorter: false,
          filter: false,
        },
        {
          key: "view",
          label: "คลิกทั้งหมด",
          sorter: false,
          filter: false,
        },
        {
          key: "detail",
          label: "",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      isLoading: true,
      tableFilterValue: "",
      columnFilterValue: {
        userRefId: null,
      },
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
    }
  },
  methods: {
    loadData() {
      this.tableLoading = true

      if (this.$route.params.userId) {
        this.columnFilterValue.userRefId = "=" + this.$route.params.userId
      }

      this.axios({
        method: "get",
        url: "users/affiliate",
        params: {
          ...this.columnFilterValue,
          page: this.activePage,
          pageSize: this.itemsPerPage,
        },
      })
        .then((res) => {
          this.items = res.data.data
          this.isLoading = false
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.$swal({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
          })
        })
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>